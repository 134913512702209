import { onMounted, ref } from 'vue'
import { UserRepository } from '@/repositories/UserRepository'
import { User } from '@/types/user'

export const useUserList = () => {
  const loading = ref(true);
  const userList = ref<User[]>([]);
  const repository = new UserRepository();

  const load = async () => {
    loading.value = true;
    userList.value = await repository.getList();
    loading.value = false;
  };

  onMounted(() => {
    load();
  });

  return {
    userList,
    loading,
    load,
  };
};

export const useDeletedUserList = () => {
  const loading = ref(true);
  const userList = ref<User[]>([]);
  const repository = new UserRepository();

  const load = async () => {
    loading.value = true;
    userList.value = await repository.getDeletedList();
    loading.value = false;
  };

  onMounted(() => {
    load();
  });

  return {
    userList,
    loading,
    load,
  };
};
